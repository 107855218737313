







































































































































































import Vue from 'vue'
import { mapActions } from "vuex";
import {required, between, minValue} from "vuelidate/lib/validators";
import { UserRegisterData } from "@/types";
import i18n from "@/i18n/i18n";
import DatePicker from "@/mixins/DatePicker";
export default Vue.extend({
  mixins: [DatePicker],
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
      currentPage: {
          type: Number,
          default: 1
      }
    },

  data() {
      return {
        endDateApplied: false,
        endsAtError: false,
        percentageDiscount: '',
        duration: '',
        campaignName: '',
        maxUses: '',
        subscriptionPackageTypeId: '',
        endsAt: "",
        isSubmitted: false,
        lang: DatePicker,
        i18: i18n
      }
  },

  computed: {
    discountRate(){
      return i18n.t("Discount rate")
    },
    maximumUsages(){
      return i18n.t("Maximum usages")
    },
    campaign(){
      return i18n.t("Campaign name")
    },
    validFor(){
      return i18n.t("Valid for")
    },
    endDate(){
      return i18n.t("End date")
    },
    isBasicChecked(){
      return this.subscriptionPackageTypeId.includes('2')
    }
  },

    //@ts-ignore
    validations:{
      percentageDiscount: {required, between: between(1, 100)},
      maxUses: {minValue: minValue(1)},
      duration: {required},
      campaignName: {required},
      subscriptionPackageTypeId: {required},
    },

    methods: {
      ...mapActions('discountsModule',{
        createDiscountCode: "CREATE_DISCOUNT"
      }),
      onSubmit(event) {
        event.preventDefault()
        this.isSubmitted = true;

        if(this.endDateApplied && this.endsAt == ''){
          this.endsAtError = true
          return
        }
        this.endsAtError = false;
        this.$v.$touch();
        if(this.$v.$invalid) return;
        const discountPayload = {
          percentageDiscount: Number(this.percentageDiscount),
          duration: this.duration,
          campaignName: this.campaignName,
          maxUses:  Number(this.maxUses),
          endsAt: this.endsAt ? String(new Date(this.endsAt).getTime()/1000) : '',
          subscriptionPackageTypeId: String(this.subscriptionPackageTypeId.reduce((a, b) => Number(a) + Number(b)))
        }
        this.createDiscountCode({payload: discountPayload, currentPage: this.currentPage})
        this.isSubmitted = false;

        this.endDateApplied = false;
        this.percentageDiscount = '';
        this.duration = '';
        this.campaignName = '';
        this.maxUses = '';
        this.subscriptionPackageTypeId = '';
        this.endsAt = "";
        this.isSubmitted = false;

        this.$bvModal.hide("create-discount-code");
        },
      disabledRange(date) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return date < today;
      },


},

})
