































import Vue from "vue";
import SearchBar from "@/components/search-bar.vue";
import { mapActions, mapGetters } from "vuex";
import i18n from "@/i18n/i18n";
export default Vue.extend({
  components: {
    SearchBar
  },
  data() {
    return {
      searchText:'',
      currentPage: 1,
      perPage: 10,
    }
  },

  async mounted(): Promise<void>{
    await this.setDiscountCodes(this.currentPage);
  },
  computed:{
    ...mapGetters("discountsModule", {
      discounts: "GET_DISCOUNTS",
      discountsTotal: "GET_DISCOUNTS_TOTAL_COUNT"
    }),
    loc_discounts(){
      // if(this.searchText!=''){
      //   return this.discounts.filter(item=>item.code.toString().includes(this.searchText))
      // }
      // else{
      //   return this.discounts
      return this.discounts
      // }
    },
    discountsTotalCount(){
      return this.discountsTotal
    },
    fields(){
      //@ts-ignore
        return [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'code',
          label: this.$i18n.t("Discount code"),
        },
        {
          key: 'percentageDiscount',
          label: this.$i18n.t("Discount rate"),
          formatter: (value, key, item) => {
            return `${value}%`;
          }
        },
        {
          key: 'maxUses',
          label: this.$i18n.t("Maximum usages"),
        },
        {
          key: 'subscriptionPackageTypeId',
          label: this.$i18n.t("Applies for")
        },
        {
          key: 'duration',
          label: this.$i18n.t("Type"),
          formatter: (value, key, item) => {
            return value == "once" ? this.$i18n.t("Only for first time payment") : this.$i18n.t("Valid forever or till end date")
          }
        },
        {
          key: 'campaignName',
          label: this.$i18n.t("Campaign name"),
        },
        // {
        //     key: 'action',
        // },
      ]
    }
  },
  methods: {
    ...mapActions("discountsModule",{
      setDiscountCodes: "FETCH_DISCOUNTS"
    }),
    getVal(it){
      return  it==1 ? 'Basic' : it!=2 ? 'Both':'Premium '
    },
    getVariant(item: number) {
      if(item === 1) {
        return 'purple'
      }
      if(item === 2) {
        return 'primary'
      }
      else{
        return 'primary'
      }
    },
    getSearchValue(val){
      this.searchText=val
    },
  },
  watch: {
    currentPage: {
      handler: async function(){
        await this.setDiscountCodes(this.currentPage);
        this.$emit('pageChanged', this.currentPage)
      },
      deep: true,
      immediate: true
    }
  }
});
