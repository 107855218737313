





























import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import DiscountCodesTable from "@/components/discount-codes/DiscountCodesTable.vue"
import AddEditDiscountCode from "@/components/modals/AddEditDiscountCode.vue"
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
  components: {
    Layout,
    DiscountCodesTable,
    AddEditDiscountCode
  },

  methods: {
    setModal(value) {
      this.edit=false
      this.showModal = value
    },
    setEditModal(value){
      this.edit=true
      this.showModal = value
    },
  },

  data() {
    return {
      edit:false,
      showModal: false,
      searchText:'',
      title: "Discount Codes",
      currentPage: 1,

      // discounts: [
      //   {
      //       id: 1,
      //       title: "Missing Company Logo",
      //       discount_code: 'XOOZSTDBP',
      //       discount_rate: '20%',
      //       subscription_plans: ['Premium', 'Standard'],
      //       type: 'Valid till end date',
      //       compaign_name: 'Christmas event',
      //       uses: 5,
      //   },
      //   {
      //       id: 2,
      //       title: "Missing Company Logo",
      //       discount_code: 'XOOZSTDBP',
      //       discount_rate: '20%',
      //       subscription_plans: ['Premium'],
      //       type: 'Valid till end date',
      //       compaign_name: 'Christmas event',
      //       uses: 5,
      //   },
      //   {
      //       id: 3,
      //       title: "Missing Company Logo",
      //       discount_code: 'XOOZSTDBP',
      //       discount_rate: '20%',
      //       subscription_plans: ['Standard'],
      //       type: 'Valid till end date',
      //       compaign_name: 'Christmas event',
      //       uses: 5,
      //   },
      // ],
    };
  },
});
